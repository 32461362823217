import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import {graphql} from "gatsby";

export const query = graphql`
    {
        allSanitySettings {
    edges {
      node {
        addresszeile1
        addresszeile2
        addresszeile3
        addresszeile4
        fblink
        instalink
        kontaktemail
      }
    }
  }
        }
    `;

const Kontakt = ({data}) => {

    const kontakt = data.allSanitySettings.edges[0].node;

    return (
        <Layout newsletter={false} page={"kontakt"}>
            <SEO title={"Kontakt"}/>
            <div className="basepage__outer flexmobile">
                <div className="basepage__inner">
                    <div className="upper">
                        <h4 className={"uppercase"}>Kontakt</h4>
                        <p>{kontakt.addresszeile1}</p>
                        <p>{kontakt.addresszeile2}</p>
                        <p>{kontakt.addresszeile3}</p>
                        <p>{kontakt.addresszeile4}</p>
                    </div>
                    <div className="lower">
                        <a href={"mailto:" + kontakt.kontaktemail} rel="noreferrer" className="kontakt__link hover-moveup underline">{kontakt.kontaktemail}</a>
                        <a href={"https:/www.facebook.com/" + kontakt.fblink + "/"} rel="noreferrer" className="kontakt__link hover-moveup underline"  target={"_blank"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.772" height="22.79" viewBox="0 0 12.772 22.79">
                                <path className={"svg-path svg-path-dark"} id="Path_229" data-name="Path 229" d="M2673.726,1218.01h-3.811c0-.087-.01-.175-.01-.263v-9.227h-3.176v-3.707h3.176v-.248c0-.778-.016-1.558.006-2.336a9.211,9.211,0,0,1,.137-1.491,4.17,4.17,0,0,1,3.672-3.457,14.919,14.919,0,0,1,2.791-.008c.329.017.657.065.985.1v3.29c-.817.012-1.634.006-2.45.042a1.252,1.252,0,0,0-1.248.994,1.926,1.926,0,0,0-.07.479c-.01.833-.009,1.665-.011,2.5a1.314,1.314,0,0,0,.022.155h3.625l-.477,3.7h-3.172v.244q0,4.507,0,9.016C2673.716,1217.862,2673.723,1217.937,2673.726,1218.01Z" transform="translate(-2665.729 -1196.22)"/>
                            </svg>
                            {kontakt.fblink}
                        </a>
                        <a href={"https:/www.instagram.com/" + kontakt.instalink + "/"} rel="noreferrer" className="kontakt__link hover-moveup underline" target={"_blank"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.12" height="23.116" viewBox="0 0 23.12 23.116">
                                <g className={"svg-path svg-path-dark"} id="Group_127" data-name="Group 127" transform="translate(-2693.769 -1195.39)">
                                    <path id="Path_226" data-name="Path 226" d="M2710.527,1217.505h-10.393a11.641,11.641,0,0,1-1.323-.281,5.756,5.756,0,0,1-4.035-5.535c-.013-3.162,0-6.323,0-9.485a5.5,5.5,0,0,1,.725-2.82,5.683,5.683,0,0,1,5.153-2.994c3.12-.02,6.24-.008,9.359,0a5.507,5.507,0,0,1,1.167.107,5.7,5.7,0,0,1,4.537,4.3c.086.308.118.632.175.949v10.4c-.035.216-.058.435-.106.647a5.721,5.721,0,0,1-4.31,4.542C2711.165,1217.418,2710.843,1217.449,2710.527,1217.505Z" transform="translate(0 0)"/>
                                    <path id="Path_227" data-name="Path 227" d="M2709.924,1206.2a5.336,5.336,0,1,1-5.328-5.337A5.347,5.347,0,0,1,2709.924,1206.2Z" transform="translate(0.742 0.743)"/>
                                    <path id="Path_228" data-name="Path 228" d="M2709.225,1199.331a1,1,0,0,0-.977,1.008,1.026,1.026,0,0,0,.985.967,1,1,0,0,0,.99-1A.987.987,0,0,0,2709.225,1199.331Z" transform="translate(2.231 0.489)"/>
                                </g>
                            </svg>
                            {kontakt.instalink}
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Kontakt;
